export class SearchParams {
	private search: Array<[string, string]>;

	constructor(search?: string | Array<[string, string]> | {[key: string]: string | string[]}) {
		this.append = this.append.bind(this);
		this.get = this.get.bind(this);
		this.getAll = this.getAll.bind(this);
		this.has = this.has.bind(this);
		this.toString = this.toString.bind(this);
		this.search = [];

		if (!search) {
			return;
		}

		if (Array.isArray(search)) {
			this.search = search;
		} else if (typeof search === 'string') {
			this.search = this.stringToSearch(search.slice(0, 1) === '?' ? search : `?${search}`);
		} else {
			this.search = this.objectToSearch(search);
		}
	}

	append(key: string, val: string): void {
		this.search.push([key, val]);
	}

	get(key: string): string {
		const val = this.search.find((s) => s[0] === key);
		if (val) {
			return val[1];
		}
		return '';
	}

	getAll(key: string): string[] {
		return this.search.filter((p) => p[0] === key).map((p) => p[1]);
	}

	has(key: string): boolean {
		return this.search.findIndex((s) => s[0] === key) !== -1;
	}

	toString(): string {
		if (this.search.length === 0) {
			return '';
		}

		return `?${this.search.map((tuple) => tuple.join('=')).join('&')}`;
	}

	private objectToSearch(search: {[key: string]: string | string[]}): Array<[string, string]> {
		const s: Array<[string, string]> = [];
		for (const key in search) {
			if (Object.hasOwnProperty.call(search, key) && search[key]) {
				const value: string | string[] = search[key];

				if (Array.isArray(value)) {
					for (const v of value) {
						s.push([key, encodeURIComponent(v)]);
					}
				} else {
					s.push([key, encodeURIComponent(value)]);
				}
			}
		}
		return s;
	}

	private stringToSearch(search: string): Array<[string, string]> {
		const s: Array<[string, string]> = [];
		search
			.slice(1)
			.split('&')
			.forEach((part) => {
				const [key, val] = part.split('=');
				if (key && val) {
					s.push([key, decodeURIComponent(val)]);
				}
			});
		return s;
	}
}
