import {envEq} from 'src/utils/env';

import {RenderRuntime} from 'src/apps/desktop/node-main/remoting';

export let isElectron: () => boolean;

interface Runtime {
	runtime: RenderRuntime;
}

declare global {
	interface Window {
		VidexioRT?: Runtime | RenderRuntime;
	}
}

if (envEq('IS_ELECTRON_ALWAYS_TRUE', 'true')) {
	isElectron = () => true;
} else {
	isElectron = () => {
		return (
			'VidexioRT' in window &&
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			(('runtime' in window.VidexioRT! && 'ready' in window.VidexioRT.runtime) ||
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				'ready' in window.VidexioRT!)
		);
	};
}
