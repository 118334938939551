export type LEVEL = 'SILLY' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'EXCEPTION' | 'RAW';

// tslint:disable-next-line: no-any
export type LogMessage = NonNullable<any>;

export interface LogWriter {
	close: () => void;
	open: (filename: string, logToConsole: boolean) => void;
	writeRecord: (level: LEVEL, name: string, ...args: LogMessage[]) => void;
}

export function isError(message: LogMessage): message is Error {
	return message instanceof Error;
}

export function formatError(error: Error): string[] {
	const message = error.message || 'No error.message available';
	const name = error.name || 'Unknown error';

	let stack: string | string[] = 'No stack-trace available';
	if (error.stack) {
		stack = error.stack
			.replace(/^[^(]+?[\n$]/gm, '')
			.replace(/^\s+at\s+/gm, '')
			.replace(/^Object.<anonymous>\s*\(/gm, '{anonymous}()@')
			.split('\n');
	}

	return [`${name}: ${message}`].concat(stack);
}

export class Logger {
	silly: (msg: string, ...args: LogMessage[]) => void;
	debug: (msg: string, ...args: LogMessage[]) => void;
	info: (msg: string, ...args: LogMessage[]) => void;
	warn: (msg: string, ...args: LogMessage[]) => void;
	error: (msg: string, ...args: LogMessage[]) => void;
	exception: (msg: string, error: Error) => void;

	private name: string;
	private writer: LogWriter | null;
	private children: {[key: string]: Logger};
	private level: LEVEL;
	private levels: LEVEL[];
	private buffer: Array<{level: LEVEL; msg: string; args: LogMessage[]}> = [];
	private buffering: boolean;

	constructor(name: string, writer: LogWriter | null, level: LEVEL, buffering = false) {
		this.name = name || '';
		this.writer = writer;
		this.level = level;
		this.buffering = buffering;
		this.children = {};

		this.close = this.close.bind(this);
		this.open = this.open.bind(this);
		this.setLevel = this.setLevel.bind(this);
		this.getLogger = this.getLogger.bind(this);
		this.flush = this.flush.bind(this);
		this.raw = this.raw.bind(this);

		this.levels = this.getLevels(this.level);
		this.log = this.log.bind(this);

		this.silly = this.log('SILLY');
		this.debug = this.log('DEBUG');
		this.info = this.log('INFO');
		this.warn = this.log('WARN');
		this.error = this.log('ERROR');
		this.exception = this.log('EXCEPTION');
	}

	close() {
		if (this.writer) {
			this.writer.close();
			this.writer = null;
		}
	}

	open(filename: string, logToConsole: boolean) {
		if (this.writer) {
			this.writer.open(filename, logToConsole);
		}
	}

	setLevel(level: LEVEL, output = true) {
		this.level = level;
		this.levels = this.getLevels(this.level);
		Object.keys(this.children).forEach((each) => {
			this.children[each].setLevel(this.level, false);
		});
		if (output) {
			this.raw('RAW', 'logger', [`setting new level: ${this.level}`, `new levels: ${this.levels.join(', ')}`]);
		}
	}

	getLogger(name: string) {
		if (!this.children[name]) {
			const newName: string = this.name ? `${this.name}/${name}` : name;
			this.children[name] = new Logger(newName, this.writer, this.level, this.buffering);
		}
		return this.children[name];
	}

	flush() {
		while (this.buffer.length) {
			const line = this.buffer.shift();
			if (line) {
				this.raw(line.level, this.name, line.msg, ...line.args);
			}
		}

		this.buffering = false;

		Object.keys(this.children).forEach((each) => {
			this.children[each].flush();
		});
	}

	raw(level: LEVEL, name: string, ...args: LogMessage[]) {
		if (this.writer) {
			this.writer.writeRecord(level, name, ...args);
		}
	}

	private getLevels(level: LEVEL): LEVEL[] {
		const levels: LEVEL[] = ['SILLY', 'DEBUG', 'INFO', 'WARN', 'ERROR', 'EXCEPTION'];
		return levels.slice(levels.indexOf(level));
	}

	private log(level: LEVEL) {
		return (msg: string, ...args: LogMessage[]) => {
			if (!this.levels.includes(level)) {
				return;
			}

			if (this.buffering) {
				return this.buffer.push({level, msg, args});
			}

			this.raw(level, this.name, msg, ...args);
		};
	}
}
