import {envEq} from 'src/utils/env';
import {features, Flag} from 'src/utils/features';
import {getRenderRuntime} from 'src/utils/getRenderRuntime';
import {isElectron} from 'src/utils/isElectron';
import {isOffice} from 'src/utils/isOffice';
import {LogWriter as LogWriterImplementation, LogMessage, LEVEL, isError, formatError} from 'src/utils/logger/Logger';
import {writeRecordConsole} from 'src/utils/logger/console';

import {RenderRuntime} from 'src/apps/desktop/node-main/remoting';

function formatMessages(args: LogMessage[]) {
	let messages;
	if (args.length === 1 && typeof args[0] === 'string') {
		// Single argument is string
		messages = args;
	} else {
		// Format error objects before sending them over the wire to the
		// electron runtime
		messages = args.map((m) => {
			if (isError(m)) {
				return formatError(m);
			}
			return m;
		});
	}
	return messages;
}

function getElectronRecordWriter(runtime: RenderRuntime) {
	return function writeRecordElectron(level: LEVEL, name: string, ...args: LogMessage[]) {
		const messages = formatMessages(args);
		runtime.writeLogRecord(level, name, messages);
	};
}

function getExternalRecordWriter(loggerSocket: WebSocket | null) {
	return function writeRecordExternal(level: LEVEL, name: string, ...args: LogMessage[]) {
		const messages = formatMessages(args);
		if (loggerSocket && loggerSocket.readyState === loggerSocket.OPEN) {
			loggerSocket.send(
				JSON.stringify({
					level,
					name,
					messages,
				})
			);
		}
	};
}

const getExternalRecordSpammer = () => (level: LEVEL, name: string, ...args: LogMessage[]) => {
	const messages = formatMessages(args);
	fetch('/httpLogSink', {
		body: JSON.stringify({level, name, messages}),
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
	}).catch((error) => {
		// tslint:disable-next-line:no-console
		console.error(error);
	});
};

export class LogWriter implements LogWriterImplementation {
	writeRecord: (level: LEVEL, name: string, ...messages: LogMessage[]) => void;
	private loggerSocket: WebSocket | null = null;

	constructor(_filename = '') {
		this.writeRecord = isElectron() ? getElectronRecordWriter(getRenderRuntime()) : writeRecordConsole;
	}

	open(_filename: string) {
		if (!features.has(Flag.LogExternal)) {
			// tslint:disable-next-line:no-console
			console.log('external logging not enabled');
			return;
		}
		if (isOffice()) {
			// tslint:disable-next-line:no-console
			console.log('Setting up http log spammer');
			this.writeRecord = getExternalRecordSpammer();
		} else if (!envEq('NODE_ENV', 'production')) {
			const isHttps = window.location.protocol.indexOf('https') !== -1;
			this.loggerSocket = isHttps
				? new WebSocket(`wss://${window.location.host}/logger`)
				: new WebSocket(`ws://${window.location.host}/logger`);
			this.loggerSocket.onopen = () => {
				// tslint:disable-next-line:no-console
				console.log('Websocket open');
			};

			this.loggerSocket.onerror = (error) => {
				// tslint:disable-next-line:no-console
				console.log(`Websocket error: ${JSON.stringify(error)}`);
			};

			window.addEventListener('online', () => {
				if (this.loggerSocket && this.loggerSocket.readyState !== 1) {
					this.loggerSocket = isHttps
						? new WebSocket(`wss://${window.location.host}/logger`)
						: new WebSocket(`ws://${window.location.host}/logger`);
					this.loggerSocket.onopen = () => {
						// tslint:disable-next-line:no-console
						console.log('Websocket open');
					};
				}
			});
			this.writeRecord = getExternalRecordWriter(this.loggerSocket);
		}
	}

	close() {
		this.writeRecord = isElectron() ? getElectronRecordWriter(getRenderRuntime()) : writeRecordConsole;
	}
}
