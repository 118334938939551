/* tslint:disable: no-console no-typeof-undefined */
import {detect} from 'src/utils/detect';
import {LEVEL, LogMessage} from 'src/utils/logger/Logger';

type LogFn = (...args: unknown[]) => void;

function consoleLog(...args: unknown[]) {
	if (console && console.log) {
		console.log(...args);
	}
}

function consoleInfo(...args: unknown[]) {
	if (console && console.info) {
		console.info(...args);
	} else {
		consoleLog(...args);
	}
}

function consoleWarn(...args: unknown[]) {
	if (console && console.warn) {
		console.warn(...args);
	} else {
		consoleLog(...args);
	}
}

export function consoleError(...args: unknown[]) {
	if (console && console.error) {
		console.error(...args);
	} else {
		consoleLog(...args);
	}
}
const logFunctions: {[key in LEVEL]: LogFn} = {
	SILLY: consoleLog,
	DEBUG: consoleLog,
	INFO: consoleInfo,
	WARN: consoleWarn,
	ERROR: consoleError,
	EXCEPTION: consoleError,
	RAW: consoleError,
};

function consoleWrite(level: LEVEL, ...args: unknown[]) {
	logFunctions[level](...args);
}

const consoleCss = detect.chrome || detect.firefox;

const levelColor: {[key in LEVEL]: string} = {
	SILLY: 'salmon',
	DEBUG: 'steelblue',
	INFO: 'blue',
	WARN: 'orangered',
	ERROR: 'red',
	EXCEPTION: 'red',
	RAW: 'red',
};

export function writeRecordConsole(level: LEVEL, name: string, ...messages: LogMessage[]) {
	const output = Array.prototype.slice.call(messages);
	let description: string = name;

	if (!name && typeof output[0] === 'string') {
		description = output.shift();
	}

	if (consoleCss) {
		const prefix = `[${level}: ${description}]`;
		consoleWrite(level, `%c${prefix}`, `color: ${levelColor[level]};font-weight: 700`, ...messages);
	} else {
		consoleWrite(level, `[${level}: ${description}]`, ...messages);
	}
}
