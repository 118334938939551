import {hasOwnProperty} from 'src/utils/hasOwnProperty';

import {RenderRuntime, RenderEvents} from 'src/apps/desktop/node-main/remoting';

const isRenderRuntime = (t: unknown): t is RenderRuntime =>
	typeof t === 'object' &&
	!!t &&
	[
		'checkForUpgrade',
		'closeIncomingCall',
		'downloadUpgrade',
		'getAppChannel',
		'getAppVersion',
		'getVersion',
		'installUpgrade',
		'isStartOnLoginEnabled',
		'openExternalLocation',
		'ready',
		'setAppChannel',
		'setLegacySettingsHasBeenRead',
		'setPinned',
		'setStartOnLoginEnabled',
		'showIncomingCall',
		'showLogfile',
		'showLogfileLocation',
		'showSoftwareLicenses',
		'toggleDevTools',
		'webUpgradeAvailable',
		'writeLogRecord',
		'zoomFactor',
		'zoomIn',
		'zoomOut',
		'zoomReset',
	].every((key) => key in t);

declare global {
	interface Window {
		VidexioRE?: RenderEvents;
		VidexioRT?: {runtime: RenderRuntime} | RenderRuntime;
	}
}

export function getRenderRuntime() {
	if (window.VidexioRT && 'runtime' in window.VidexioRT) {
		return window.VidexioRT.runtime;
	}

	if (!isRenderRuntime(window.VidexioRT)) {
		throw new Error('Runtime not available');
	}

	if ('supports' in window.VidexioRT) {
		return window.VidexioRT;
	}

	// HACK: This is not available on clients < 12, shim it
	(window.VidexioRT as RenderRuntime).supports = (version: number) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return (window.VidexioRT as RenderRuntime).getAppVersion() >= version;
	};

	return window.VidexioRT;
}

export function getRenderEvents(isSecure: boolean) {
	if (isSecure) {
		return {} as RenderEvents;
	}

	const w = window as {};
	if (!hasOwnProperty(w, 'VidexioRE') || !w.VidexioRE) {
		throw new Error('Runtime events not available');
	}
	return w.VidexioRE as RenderEvents;
}
