import {envEq} from 'src/utils/env';
import {isElectron} from 'src/utils/isElectron';
import {isOffice} from 'src/utils/isOffice';
import {LogWriter} from 'src/utils/logger/LogWriter';
import {
	Logger,
	LEVEL as InternalLEVEL,
	LogMessage as LogMessageAny,
	LogWriter as LogWriterImplementation,
} from 'src/utils/logger/Logger';

let defaultLogLevel: InternalLEVEL = 'ERROR';

if (!envEq('NODE_ENV', 'production')) {
	defaultLogLevel = 'DEBUG';
} else if (envEq('NODE_ENV', 'production') && (isElectron() || isOffice())) {
	defaultLogLevel = 'INFO';
}

const logWriter: LogWriterImplementation = new LogWriter('');
export const logger = new Logger('', logWriter, defaultLogLevel, isElectron() || isOffice());
export type LEVEL = InternalLEVEL;
export type LogMessage = LogMessageAny;
