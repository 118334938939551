export enum Flag {
	AuthEndpoint = 'auth-endpoint',
	BlacklistH264 = 'blacklist-h264',
	BlacklistH264Firefox = 'blacklist-h264-firefox',
	BlacklistH264Presentation = 'blacklist-h264-presentation',
	CameraControl = 'camera-control',
	Debug = 'debug',
	DesktopWindowSharing = 'desktop-window-sharing',
	DevProv = 'dev-prov',
	DisableTurn = 'disable-turn',
	EditLiveEvents = 'edit-live-events',
	EnableMuteOnJoin = 'enable-mute-on-join',
	EnableStreamKicker = 'enable-stream-kicker',
	EnableTownhall = 'enable-townhall',
	EnterTheLobby = 'enter-the-lobby',
	FakeCamera = 'fake-camera',
	FastUpgradeCheck = 'fast-upgrade-check',
	FFIncallDeviceSwitch = 'ff-in-call-device-switch',
	GoogleCalendar = 'google-calendar',
	HigherBandwidths = 'higher-bandwidths',
	LimitBandwidthHigh = 'limit-bandwidth-high',
	LimitBandwidthLow = 'limit-bandwidth-low',
	LimitBandwidthMedium = 'limit-bandwidth-medium',
	LogExternal = 'log-external',
	MoonProfile = 'moon-profile',
	PresentationBundling = 'presentation-bundling',
	RequirePacketizationMode = 'require-packetization-mode',
	SafariAllowScreenSharing = 'safari-allow-screen-sharing',
	SimpleSDPMangling = 'simple-sdp-mangling',
	TeamsHideQR = 'hide-qr',
	TeamsHideSkype = 'hide-skype',
	UseDevmoon = 'use-devmoon',
	UseDevmoonForSchedule = 'use-devmoon-for-schedule',
	VeryHighBandwidthOption = 'very-high-bandwidth-option',
	VeryLowBandwidthOption = 'very-low-bandwidth-option',
	UseDevEnvironment = 'use-dev-environment',
}

export const features: Set<Flag> = new Set([]);

const flags: Flag[] = Object.values(Flag);
type FeatureList = Array<{feature: Flag; enabled: boolean}>;
export const listFeatures = (): FeatureList => {
	return flags.map((feature: Flag) => ({feature, enabled: features.has(feature)}));
};
