// Are we using this? -ea
import '@babel/runtime/regenerator';
import 'core-js/es/map';
import 'core-js/es/number';
import 'core-js/features/array';
import 'core-js/features/map';
import 'core-js/features/object';
import 'core-js/features/promise';
import 'core-js/features/string';
import 'core-js/features/symbol';
import 'whatwg-fetch';

// fastclick seems to be needed to get proper click handling on mobile
if ('addEventListener' in document) {
	document.addEventListener(
		'DOMContentLoaded',
		function fastclick() {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const FastClick = require('fastclick');
			FastClick.attach(document.body);
		},
		false
	);
}

Math.log10 =
	Math.log10 ||
	function log10(x) {
		return Math.log(x) * Math.LOG10E;
	};
