import * as bowser from 'bowser';

interface Detect {
	mobile: boolean;
	tablet: boolean;
	computer: boolean;

	android: boolean;
	ios: boolean;
	mac: boolean;
	linux: boolean;
	windows: boolean;
	windows7: boolean;
	windows10: boolean;

	ie: boolean;
	msie: boolean;
	opera: boolean;
	vivaldi: boolean;
	msedge: boolean;
	chrome: boolean;
	safari: boolean;
	firefox: boolean;

	webkit: boolean;
	blink: boolean;
	gecko: boolean;

	name: string;
	osname?: string;
	osversion?: string;
	version: string;
	major: number;
	webrtc: boolean;
	canSetAudioSink: boolean;
	canCopyClipboard: boolean;
}

/**
 * @module detect
 * @private
 */

function getMajor(version: string | number) {
	const v = `${version}`;
	if (v && v.indexOf('.') !== -1) {
		return Number(v.split('.')[0]).valueOf();
	}
	return Number(version).valueOf();
}
/**
 * Tries creating a video element and setting sinkId.
 * On supported browsers this should work and true is returned.
 * On unsupported the catch should happen and false is returned.
 *
 * @returns boolean
 */
function canSetAudioSinkCheck() {
	try {
		window.document.createElement('video').setSinkId('default');
		return true;
	} catch (_e) {
		return false;
	}
}

/**
 * A helper to make it easier to switch detection library if ever needed.
 * @returns {Object} - A bunch of booleans
 */
function _detect(): Detect {
	const detected: bowser.IBowser = bowser._detect(navigator.userAgent);
	const major = getMajor(detected.version);
	const canSetAudioSink = canSetAudioSinkCheck();

	// HACK: guess iPadOS (iOS 13+ on iPad) by its touch screen, 'cause the User-Agent is identical to macOS
	const ipados = detected.mac && 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 2;
	const ios = detected.ios || ipados;
	const mac = detected.mac && !ipados;

	const tablet = detected.tablet || ipados;
	const computer = !(detected.mobile || tablet);

	return {
		mobile: detected.mobile,
		tablet,
		computer,

		android: detected.android,
		ios,
		mac,
		linux: detected.linux,
		windows: detected.windows,
		windows7: /(?:NT 6.1)/.test(navigator.userAgent),
		windows10: /(?:NT 10.0)/.test(navigator.userAgent),

		ie: detected.msie,
		msie: detected.msie,
		opera: detected.opera,
		vivaldi: detected.vivaldi,
		msedge: detected.msedge,
		chrome: detected.chrome || detected.chromium, // Treat chromium as chrome
		safari: detected.safari,
		firefox: detected.firefox,

		webkit: detected.webkit,
		blink: detected.blink,
		gecko: detected.gecko,

		name: detected.name,
		osname: detected.osname,
		osversion: `${detected.osversion}`,
		version: `${detected.version}`,
		major,
		webrtc: Boolean(
			navigator.getUserMedia ||
				((navigator as unknown) as {webkitGetUserMedia?: unknown}).webkitGetUserMedia ||
				((navigator as unknown) as {mozGetUserMedia?: unknown}).mozGetUserMedia ||
				((navigator as unknown) as {msGetUserMedia?: unknown}).msGetUserMedia ||
				window.RTCPeerConnection
		),
		canSetAudioSink,

		// disable clipboard sharing for some browsers
		canCopyClipboard: !(
			(computer && detected.safari && major < 10) ||
			(detected.ios && detected.mobile && detected.safari) ||
			(detected.ios && detected.mobile && detected.chrome) ||
			(detected.ios && detected.mobile && major < 10)
		),
	};
}

export const detect = _detect();
