import {SearchParams} from 'src/utils/Satellite/SearchParams';
import {assertUnreachable} from 'src/utils/asserts';
import {envEq} from 'src/utils/env';

type Protocol = 'http:' | 'https:' | 'ws:' | 'wss:';
const protocols: Protocol[] = ['http:', 'https:', 'ws:', 'wss:'];

// uri part names from https://tools.ietf.org/html/rfc3986#section-3
type State = 'scheme' | 'abempty' | 'authority' | 'path' | 'query' | 'fragment';
function uriParser(uri: string) {
	let state: State = 'scheme';
	const components = {
		abempty: '',
		authority: '',
		fragment: '',
		path: '',
		query: '',
		scheme: '' as Protocol,
	};

	for (const char of uri) {
		switch (state) {
			case 'scheme':
				if (char === ':') {
					state = 'abempty';
				} else {
					components.scheme += char;
				}
				break;

			case 'abempty':
				// We expect 2 slashes after scheme, collected here to test for later
				if (char !== '/') {
					state = 'authority';
					// make sure we don't miss the first char in authority
					components.authority += char;
				} else {
					components.abempty += char;
				}
				break;

			case 'authority':
				if (char === '/') {
					state = 'path';
				} else if (char === '?') {
					state = 'query';
				} else if (char === '#') {
					state = 'fragment';
				} else {
					components.authority += char;
				}
				break;

			case 'path':
				if (char === '?') {
					state = 'query';
				} else if (char === '#') {
					state = 'fragment';
				} else {
					components.path += char;
				}
				break;

			case 'query':
				if (char === '#') {
					state = 'fragment';
				} else {
					components.query += char;
				}
				break;

			case 'fragment': // not something we use, but just here to "complete" the parser
				components.fragment += char;
				break;

			default:
				assertUnreachable(state);
		}
	}

	// adds the colon to protocol
	components.scheme += ':';
	// adds the questionmark to query if needed
	components.query = components.query ? `?${components.query}` : '';

	return components;
}

function wantedPath(pathname: string) {
	// insert / at start
	const path = pathname.slice(0, 1) === '/' ? pathname : `/${pathname}`;
	return path || '/';
}

function href(base: string, location: string) {
	if (!base) {
		return location;
	}
	if (!location) {
		return base;
	}
	return `${base}${location.startsWith('/') ? location : `/${location}`}`;
}

export class Url {
	pathname: string;
	search: SearchParams;
	private internalHostname: string;
	private internalProtocol: Protocol;

	constructor(location: string, base = '') {
		const {abempty, authority, path, query, scheme} = uriParser(href(base, location));

		// We only care about http or websockets URI's
		if (!protocols.includes(scheme)) {
			throw new Error(
				`invalid protocol in "${location}", should be one of "${protocols.join('", "')}", got "${scheme}"`
			);
		}

		// It shall contain 2 slashes. No more. No less. 2 shall be the number. 3 it shall not be. 4 is right out.
		if (abempty !== '//') {
			throw new Error(`invalid authority, Expected // after protocol, got ${location}`);
		}

		// window.URI will accept anything here, but we always use it with a tld or port
		if (!['.', ':'].some((t) => authority.includes(t)) || !authority) {
			throw new Error(
				`invalid hostname in "${location}", should be set and contain a "." or ":", got "${authority}"`
			);
		}

		this.internalProtocol = scheme;
		this.internalHostname = authority;
		this.pathname = wantedPath(path);
		this.search = new SearchParams(query);

		// Something has gone wrong if there are double slashes in the path (however, not gonna crash this in prod)
		if (!envEq('NODE_ENV', 'production') && /\/\//.test(this.pathname)) {
			throw new Error(`invalid pathname, should not contain double slashes "//", got "${this.pathname}"`);
		}
	}

	get base() {
		return `${this.internalProtocol}//${this.internalHostname}`;
	}

	get href(): string {
		return `${this.internalProtocol}//${this.internalHostname}${this.pathname}${this.search.toString()}`;
	}

	get host() {
		return this.internalHostname;
	}

	get hostname() {
		return this.internalHostname.split(':')[0];
	}

	get protocol() {
		return this.internalProtocol;
	}

	set protocol(p: Protocol) {
		this.internalProtocol = p;
	}
}
