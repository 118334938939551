import {Url} from 'src/utils/Satellite/Url';
import {detect} from 'src/utils/detect';
import {envEq} from 'src/utils/env';
import {logger} from 'src/utils/logger';

// clean slate for browser console on refresh
/* tslint:disable-next-line:no-console no-typeof-undefined */
if (console && console.clear) {
	/* tslint:disable-next-line:no-console */
	console.clear();
}

const url = 'https://updatemybrowser.org/';
const show = (node: string) => {
	const rootNode = document.getElementById('root') as HTMLDivElement;
	rootNode.innerHTML = node;
};

if (detect.ie) {
	import(/* webpackChunkName: "unsupported-ie" */ './unsupported/ie')
		.then(({error}) => {
			show(error(url));
		})
		.catch((error) => logger.exception('failed to load module', error));
} else {
	import(/* webpackChunkName: "browser" */ 'src/apps/browser/browser')
		.then(({init}) => {
			if (envEq('NODE_ENV', 'development') && envEq('APP_CHANNEL', 'local')) {
				const searchParams = new Url(window.location.href).search;

				if (searchParams.has('accessToken')) {
					const mypagesOptions = {
						...window.mypagesOptions,
						accessToken: searchParams.get('accessToken'),
					};

					init(mypagesOptions);
					return;
				}
			}

			init(window.mypagesOptions);
		})
		.catch((error) => logger.exception('failed to load module', error));
}

if (envEq('NODE_ENV', 'development')) {
	window.load = (test) => {
		switch (test) {
			case 'ie':
				import(/* webpackChunkName: "unsupported-ie" */ './unsupported/ie')
					.then(({error}) => {
						show(error(url));
					})
					.catch((error) => logger.exception('failed to load module', error));
				break;
		}
	};
}
