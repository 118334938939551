/**
 * tbd
 * @param _ never
 */
export function assertUnreachable(_: never): never {
	throw new Error("Didn't expect to get here");
}

/**
 * Like {@link assertUnreachable}, but doesn't throw.
 * For use in reducers, where you only care for a subset of actions, but still get everything.
 * @param _ - should be inferred as never
 */
export function assertExhaustive(_: never) {
	/* this block is intentionally left empty */
}
/**
 * Check that we have a value or throw if we don't
 *
 * @param {(string | boolean | null | number) | {}} [value]
 */
export function assertTruthy(value?: string | boolean | null | number | {}) {
	if (!value) {
		throw new Error('Expected value to be truthy');
	}
}
