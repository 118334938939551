declare global {
	const APP_CHANNEL: 'local' | 'alpha' | 'beta' | 'prod';
	const APP_CHANNELS_ALPHA: string;
	const APP_CHANNELS_BETA: string;
	const APP_CHANNELS_LOCAL: string;
	const APP_CHANNELS_PROD: string;
	const BUILD_VERSION: string;
	const ES_VERSION: string;
	const HTML_GIT_LOG: string;
	const IS_ELECTRON_ALWAYS_TRUE: 'true' | 'false';
	const NODE_ENV: 'production' | 'development' | 'test';
	const USE_PRODUCTION_APIS: 'true' | 'false';
}

// webpack define plugin does a search and replace, so can't do `object-literal-shorthand` since the variables are not defined
// tslint:disable: object-literal-shorthand
const environment = {
	APP_CHANNEL: APP_CHANNEL,
	APP_CHANNELS_ALPHA: APP_CHANNELS_ALPHA,
	APP_CHANNELS_BETA: APP_CHANNELS_BETA,
	APP_CHANNELS_LOCAL: APP_CHANNELS_LOCAL,
	APP_CHANNELS_PROD: APP_CHANNELS_PROD,
	BUILD_VERSION: BUILD_VERSION,
	ES_VERSION: ES_VERSION,
	HTML_GIT_LOG: HTML_GIT_LOG,
	IS_ELECTRON_ALWAYS_TRUE: IS_ELECTRON_ALWAYS_TRUE,
	NODE_ENV: NODE_ENV,
	USE_PRODUCTION_APIS: USE_PRODUCTION_APIS,
};

// tslint:enable: object-literal-shorthand

type Env = typeof environment;

const pick = <T extends keyof Env>(key: T) => environment[key];

export const env = <T extends keyof Env>(key: T, defaultValue: Env[T]): Env[T] => pick(key) ?? defaultValue;

export const envEq = <T extends keyof Env>(key: T, value: Env[T]) => pick(key) === value;

export const appChannels = {
	alpha: pick('APP_CHANNELS_ALPHA'),
	beta: pick('APP_CHANNELS_BETA'),
	local: pick('APP_CHANNELS_LOCAL'),
	prod: pick('APP_CHANNELS_PROD'),
};
